// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBil0KWMjhii4booQNGKqjBzp2AP95eF5E",
    authDomain: "uniqium.com",
    projectId: "hivemind-30c87",
    storageBucket: "hivemind-30c87.appspot.com",
    messagingSenderId: "990855238026",
    appId: "1:990855238026:web:2b6e28c59c912dbdaf3873",
    measurementId: "G-HLBV6D18K9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const auth = getAuth(app);