import React from 'react';
import { Spinner } from 'react-activity';
import "react-activity/dist/library.css";

import add from '../assets/add.png'

function AddButton(props) {
    return (
        <button
            className={props.nodeLoading ? 'card-btn loading' : 'card-btn green-btn'}
            onClick={props.nodeLoading ? () => { } : props.onNodeClick}
        >
            {props.nodeLoading ? <Spinner color="#3e0000" size={9} speed={0.4} animating={true} style={{ marginLeft: -1, marginTop: -1 }} /> : <img src={add} width={10} alt='New idea button' />}
        </button>
    );
}

export default AddButton;