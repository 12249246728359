import React, { useEffect, useState, useRef } from 'react';

import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { db } from './firebase';
import { collection, doc, getDoc, getDocs, setDoc, addDoc, updateDoc } from 'firebase/firestore';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { auth } from './firebase';
import { signInWithPopup, onAuthStateChanged, GoogleAuthProvider, signOut } from 'firebase/auth';

import AddButton from './components/AddButton';
import DynamicTextArea from './components/DynamicTextArea'

import Tree from 'react-d3-tree';
import { useCenteredTree } from "./helpers";
import { Spinner } from 'react-activity';
import "react-activity/dist/library.css";
import { TwitterShareButton } from 'react-share';


import logo from './assets/uniqium_logo_sand.png';
import google from './assets/google.png';
import menu from './assets/menu.png';
import twitter from './assets/twitter.png';
import help from './assets/help.png';
import lock from './assets/lock.png';
import share from './assets/share.png';
import edit from './assets/edit.png';
import cancel from './assets/cancel.png';
import confirm from './assets/confirm.png';
import resetIcon from './assets/resetIcon.png';
import load from './assets/load.png';
import save from './assets/save.png';

function App() {
  const [translate, containerRef] = useCenteredTree();
  const [currentTree, setCurrentTree] = useState(null);
  const [newSeed, setNewSeed] = useState('');
  const [resetLoading, setResetLoading] = useState(false);
  const [signedInUser, setSignedInUser] = useState();
  const [isDropDwonOpen, setIsDropDownOpen] = useState(false);
  const [isTreeListOpen, setIsTreeListOpen] = useState(false);
  const [loadingNode, setLoadingNode] = useState(null);
  const [nodeToEdit, setNodeToEdit] = useState(null);
  const [nodeToEditText, setNodeToEditText] = useState(null);
  const [reset, setReset] = useState(false);
  const [savedTrees, setSavedTrees] = useState([]);
  const [TreeZoom, setTreeZoom] = useState(100);

  const ideaInputRef = useRef({ value: 0 });
  const nodeToEditRef = useRef({ value: 0 })
  const helpButtonRef = useRef();
  const twitterButtonRef = useRef();
  const treeRef = useRef();

  const handleSignIn = () => {
    const propvider = new GoogleAuthProvider();
    signInWithPopup(auth, propvider)
      .then((result) => {
        setSignedInUser(result.user);
        const userId = result.user.uid;
        const docRef = doc(db, 'trees', userId);
        getDoc(docRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              // Doc exists // Do nothing
            } else {
              //New user // Set new doc
              const newDocRef = doc(db, 'trees', userId);
              setDoc(newDocRef, { name: 'Click \'Reset Tree\' to begin', children: [] });
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setSignedInUser(null);
        setIsDropDownOpen(false);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const createNewIdea = (nodeName, root, userId) => {
    return new Promise((resolve, reject) => {
      const callGpt4 = httpsCallable(functions, 'callGpt4');
      callGpt4({ text: nodeName, root: root, userId: userId })
        .then((response) => {
          const idea = response.data.completionText;
          logEvent(analytics, 'new_idea', { text: idea })
          resolve(idea);
        })
        .catch((err) => console.log(err));
    });
  }

  const checkCompliance = (newSeed) => {
    return new Promise((resolve, reject) => {
      const callModeration = httpsCallable(functions, 'callModeration');
      callModeration({ text: newSeed })
        .then((response) => {
          const flagged = response.data.result;
          resolve(flagged);
        })
        .catch((err) => console.log(err));
    });
  }

  const updateTreeData = (root) => {
    // Updates the tree on Firestore
    const documentId = signedInUser.uid;
    const docRef = doc(db, 'trees', documentId);
    updateDoc(docRef, root)
    // Updates the tree locally
    setCurrentTree(root);
  }

  const handleInput = (event) => {
    const userInput = event.target.value;
    setNewSeed(userInput);
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      setNewSeed(ideaInputRef.current.value);
      ideaInputRef.current.blur();
      handleResetTree();
    }
  }

  const handleUpdateButtonClick = (event, nodeClicked) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setLoadingNode(nodeClicked);
      const editedIdea = nodeToEditRef.current.value;
      checkCompliance(editedIdea)
        .then((flagged) => {
          if (flagged) {
            alert('Your input was flagged for violent and or sexual language.');
            nodeToEditRef.current.value = '';
            setLoadingNode(null);
            setNodeToEdit(null);
          } else if (editedIdea !== '') {
            const root = { ...currentTree };
            updateNode(root, nodeClicked, editedIdea);
            updateTreeData(root);
            setLoadingNode(null);
            setNodeToEdit(null);
          } else {
            alert('Oops... You forgot to write something!');
          }
        });
    }
  }

  const handleResetTree = () => {
    setResetLoading(true);
    checkCompliance(newSeed)
      .then((flagged) => {
        if (flagged) {
          alert('Your input was flagged for violent and or sexual language.');
          setResetLoading(false);
          ideaInputRef.current.value = '';
          setNewSeed('');
        } else if (newSeed !== '') {
          const root = {
            name: newSeed,
            children: [],
          }
          updateTreeData(root);
          ideaInputRef.current.value = '';
          setNewSeed('');
          setResetLoading(false);
          setReset(false);
        } else {
          alert('Oops... You forgot to write something!');
          setResetLoading(false);
        }
      })
  }

  const doesTreeExist = () => {
    let docId = null;
    savedTrees.forEach((tree) => {
      if (tree.data().name === currentTree.name) {
        docId = tree.id;
      } else {
        // do nothing
      }
    });
    return docId;
  }

  const handleSaveTree = () => {
    const docId = doesTreeExist();
    if (docId === null) {
      const userId = signedInUser.uid;
      const collectionRef = collection(db, 'trees', userId, 'savedTrees');
      //calling addDoc instead of setDoc
      //addDoc doesn't require a document id
      //it instead uses a collection ref as defined above.
      addDoc(collectionRef, currentTree);
      alert('Tree saved successfully!');
    } else {
      const userId = signedInUser.uid;
      const docRef = doc(db, 'trees', userId, 'savedTrees', docId);
      setDoc(docRef, currentTree);
      alert('Tree saved successfully!');
    }
  }

  const loadSavedTrees = async () => {
    const userId = signedInUser.uid;
    const collectionRef = collection(db, 'trees', userId, 'savedTrees');
    const snapshot = await getDocs(collectionRef);
    const docs = [];
    snapshot.forEach((doc) => { docs.push(doc) });
    setSavedTrees(docs);
  }

  const handleOpenSavedTree = (requestedTree) => {
    setIsTreeListOpen(!isTreeListOpen);
    savedTrees.forEach((tree) => {
      if (tree.data().name === requestedTree) {
        updateTreeData(tree.data());
      }
    });
  }

  const handleEdit = (nodeClicked) => {
    if (nodeToEdit === null) {
      setNodeToEdit(nodeClicked);
      setNodeToEditText(nodeClicked);
    } else {
      setNodeToEdit(null);
      setNodeToEditText(null);
    }
  }

  const addNode = (root, nodeName, newIdea) => {
    if (root.name === nodeName) {
      root.children.push(newIdea);
    }
    for (const child of root.children) {
      addNode(child, nodeName, newIdea);
    }
  }

  const updateNode = (root, nodeClicked, editedIdea) => {
    if (root.name === nodeClicked) {
      root.name = editedIdea;
    }
    for (const child of root.children) {
      updateNode(child, nodeClicked, editedIdea);
    }
  }

  const handleAddButtonClick = (node) => {
    const userId = signedInUser.uid;

    const nodeData = node.data;
    const nodeName = nodeData.name;
    setLoadingNode(nodeName);

    const root = { ...currentTree };

    createNewIdea(nodeName, root, userId)
      .then((idea) => {
        const newIdea = { 'name': idea, 'children': [] };
        addNode(root, nodeName, newIdea);
        updateTreeData(root);
        setLoadingNode(null);
      });
  }

  const renderFunction = (props) => {
    const nodeClicked = props.nodeDatum.name;
    const nodeClickedChildren = props.nodeDatum.children;
    return (
      <foreignObject
        height={180}
        width={300}
        y={-15} x={2}
        className='custom-node-container'
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginLeft: -2 }}>
          <AddButton
            onNodeClick={props.onNodeClick}
            nodeLoading={nodeClicked === loadingNode ? true : false}
          />
          {nodeClicked !== currentTree.name &&
            <button
              className='card-btn'
              onClick={() => { handleEdit(nodeClicked) }}
            >
              <img src={edit} width={12} alt='Edit icon' />
            </button>
          }
          {nodeClicked !== currentTree.name &&
            <TwitterShareButton
              title={`Me: ${currentTree.name}\n↓\nBrainstorm Buddy: ${nodeClicked}\n\n#brainstormbuddy #gpt #ai\n\n`}
              url='www.uniqium.com'
            >
              <button className='card-btn'>
                <img src={share} width={11} alt='Share icon' style={{ marginTop: -2 }} />
              </button>
            </TwitterShareButton>
          }
        </div>
        <div className='card'>
          {nodeToEdit === nodeClicked
            ?
            <DynamicTextArea
              nodeToEditText={nodeToEditText}
              nodeToEditRef={nodeToEditRef}
              handleUpdateButtonClick={handleUpdateButtonClick}
              nodeClicked={nodeClicked}
              nodeClickedChildren={nodeClickedChildren}
              getNewNodeText={getNewNodeText}
            />
            :
            <p className='idea-text'>{props.nodeDatum.name}</p>
          }
        </div>
      </foreignObject>
    );
  }

  const getNewNodeText = (event) => {
    event.preventDefault();
    setNodeToEditText(event.target.value);
  }

  const howManyUsers = async () => {
    const colRef = collection(db, 'trees');
    let num = 0;
    const colSnap = await getDocs(colRef);
    colSnap.forEach((doc) => {
      num++;
    });
    console.log(num);
  }

  const handleZoomOut = () => {
    if (TreeZoom > 10) {
      const newZoomValue = TreeZoom - 5;
      setTreeZoom(newZoomValue);
    }
  }

  const handleZoomIn = () => {
    if (TreeZoom < 100) {
      const newZoomValue = TreeZoom + 5;
      setTreeZoom(newZoomValue);
    }
  }

  const recordPosition = (target) => {
    const x = target.translate.x;
    const y = target.translate.y;
    treeRef.current.props.translate.x = x;
    treeRef.current.props.translate.y = y;
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setTimeout(() => {
          setSignedInUser(user);
        }, 1000);
        const documentId = user.uid;
        const docRef = doc(db, 'trees', documentId);
        getDoc(docRef)
          .then(docSnapshot => setCurrentTree(docSnapshot.data()))
          .catch(err => console.log(err));
      } else {
        setSignedInUser(null);
      }
    });
    loadSavedTrees();
  }, [isTreeListOpen]);

  if (signedInUser === null) {
    return (
      <div className='sign-in-screen'>
        <div className='sign-in-card'>
          <img src={logo} width={124} alt='uniqium Logo' />
          <div className='sign-in-text-container'>
            <p className='gray centered-text'>Brainstorm ideas faster than ever with the help of advanced AI technology.</p>
          </div>
          <div className='sign-in-button'>
            <div className='google-icon'>
              <img src={google} alt='google logo' width={18} />
            </div>
            <button
              onClick={handleSignIn}
              className='custom-rounded-corners'
            >
              <span className='button-text'>Sign in with Google</span>
            </button>
          </div>
        </div>
      </div>
    );
  } else if (signedInUser === undefined) {
    return (
      <></>
    );
  } else {
    return (
      <>
        <p className='mobile-message'>
          Uniqium is only availabel on desktop at the moment.
          A mobile version is coming soon. If you'd like to be an early tester,
          reach out to us at contact@uniqium.com.
        </p>
        <div className='tree-container' ref={containerRef}>
          <div className='header'>
            <div className='nav-left-container'>
              <div className='dropdown-btn' onClick={() => setIsDropDownOpen(!isDropDwonOpen)}>
                <img src={menu} alt='menu icon' width={24} />
              </div>
              {isDropDwonOpen &&
                <div className='dropdown'>
                  <button
                    onClick={() => { setIsDropDownOpen(false); helpButtonRef.current.click() }}
                    className='dropdown-item'
                  >
                    <img className='button-icon' src={help} alt='help' width={24} />
                    <a ref={helpButtonRef} href='mailto:abe@uniqium.com' className='button-text'>Give feedback</a>
                  </button>
                  <button
                    onClick={() => { setIsDropDownOpen(false); twitterButtonRef.current.click() }}
                    className='dropdown-item'
                  >
                    <img className='button-icon' src={twitter} alt='twitter' width={24} />
                    <a ref={twitterButtonRef} href='https://www.twitter.com/uniqium' target='_blank' rel='noreferrer' className='button-text'>@uniqium</a>
                  </button>
                  <button
                    onClick={handleSignOut}
                    className='dropdown-item'
                  >
                    <img className='button-icon' src={lock} alt='lock' width={24} />
                    <span className='button-text'>Sign out</span>
                  </button>
                </div>
              }
              <img src={logo} height={30} width={125} alt='Uniqium logo' onClick={howManyUsers} />
            </div>
            <div className='nav-center-container'>
            </div>
            <div className='nav-right-container'>
              {isTreeListOpen &&
                <div className='dropdown tree-list'>
                  {savedTrees.map((tree) => {
                    return (
                      <button
                        onClick={() => { handleOpenSavedTree(tree.data().name) }}
                        className='dropdown-item'
                      >
                        <span className='button-text overflow-ellipsis'>{tree.data().name}</span>
                      </button>
                    );
                  })}
                </div>
              }
              {reset
                ?
                <div className='controls'>
                  <input
                    className={ideaInputRef.current === null ? 'idea-input' : ideaInputRef.current.value.length >= 64 ? 'idea-input toolong' : 'idea-input'}
                    placeholder='What are you working on?'
                    onInput={handleInput}
                    onKeyDown={handleEnterPress}
                    ref={ideaInputRef}
                    maxLength={65}
                  />
                  <button className={resetLoading ? 'loading-confirm flex-center' : 'green-btn flex-center'} onClick={handleResetTree}>
                    {resetLoading ? <Spinner color='#3e0000' size={12} speed={0.45} animating={true} style={{ marginBottom: 2, marginLeft: -1 }} /> : <img src={confirm} height={12} alt='Confirm icon' />}
                  </button>
                  <button onClick={() => { setReset(false) }} className='flex-center' ><img src={cancel} width={12} alt='Cancel icon' /></button>
                </div>
                :
                <div style={{ display: 'flex', gap: 8 }}>
                  {/* <button className='control-btn' onClick={handleSaveTree}>
                    <img className='button-icon' src={save} width={18} alt='Save icon' />
                    <span className='button-text'>Save Tree</span>
                  </button>
                  <button className='control-btn' onClick={() => { setIsTreeListOpen(!isTreeListOpen) }}>
                    <img className='button-icon' src={load} width={16} alt='Load icon' />
                    <span className='button-text'>Load Tree</span>
                  </button> */}
                  <button className='control-btn' onClick={() => { setReset(true) }}>
                    <img className='button-icon' src={resetIcon} width={20} alt='Reset icon' />
                    <span className='button-text'>Reset Tree</span>
                  </button>
                </div>
              }
            </div>
          </div>
          {currentTree === null ? <div className='loading-container'><p className='gray'>Loading your tree...</p></div> :
            <Tree
              ref={treeRef}
              data={currentTree}
              translate={translate}
              onNodeClick={(node) => { handleAddButtonClick(node) }}
              collapsible={false}
              nodeSize={{ x: 600, y: 280 }}
              renderCustomNodeElement={(props) => renderFunction(props)}
              zoom={TreeZoom / 100}
              zoomable={true}
              hasInteractiveNodes={true}
            // onUpdate={(target) => { recordPosition(target) }}
            />}
        </div>
        {/* I need to add a toggle that disables scroll to zoom and shows on screen controls and this will be optional */}
        {/* <div className='zoom-controls'>
          <button className='zoom-btn' onClick={() => handleZoomIn()}>Zoom In</button>
          <button className='zoom-btn' onClick={() => handleZoomOut()}>Zoom Out</button>
          <button className='zoom-btn' onClick={() => setTreeZoom(100)}>Reset</button>
        </div> */}
      </>
    );
  }
}

export default App;